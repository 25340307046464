import React, { useEffect } from 'react';
import './experience.css';

const Experience = () => {
  // Animate timeline items when they come into view
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const content = entry.target.querySelector('.timeline-content');
            if (content) {
              content.style.opacity = '1';
              content.style.transform = 'translateY(0)';
            }
          }
        });
      },
      { threshold: 0.1 }
    );

    const timelineItems = document.querySelectorAll('.timeline-item');
    timelineItems.forEach(item => {
      // Set initial styles
      const content = item.querySelector('.timeline-content');
      if (content) {
        content.style.opacity = '0';
        content.style.transform = 'translateY(20px)';
        content.style.transition = 'opacity 0.5s ease, transform 0.5s ease';
      }
      
      observer.observe(item);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <section id="experience">
      <div className="container">
        <div className="center-wrapper">
          <h2 className="section-title">Work Experience</h2>
          <p className="section-subtitle">
            A timeline of my professional journey, showcasing my career growth and the valuable experiences I've gained along the way.
          </p>
        </div>
        
        <div className="timeline-container">
          <div className="timeline-center-line"></div>
          
          {/* Peak Support */}
          <div className="timeline-item">
            <div className="timeline-dot"></div>
            <div className="timeline-date">Feb 2020 - Present</div>
            <div className="company-logo">
              <div className="logo-placeholder">PS</div>
            </div>
            <div className="timeline-content">
              <h3 className="timeline-title">Peak Support</h3>
              <h4 className="timeline-subtitle">Front-End Support Engineer</h4>
              <div className="timeline-achievements">
                <div className="achievement-item">
                  Built and optimized landing pages using HTML5, CSS3, JavaScript, and React for desktop and mobile platforms.
                </div>
                <div className="achievement-item">
                  Customized WordPress websites for premium clients, enhancing user engagement and conversion rates.
                </div>
                <div className="achievement-item">
                  Collaborated with cross-functional teams to maintain design consistency and ensure top-notch customer service.
                </div>
              </div>
              <div className="timeline-tags">
                <span className="timeline-tag">React</span>
                <span className="timeline-tag">JavaScript</span>
                <span className="timeline-tag">WordPress</span>
                <span className="timeline-tag">HTML/CSS</span>
              </div>
            </div>
          </div>
          
          {/* Pixolo Productions */}
          <div className="timeline-item">
            <div className="timeline-dot"></div>
            <div className="timeline-date">Apr 2018 - Jul 2019</div>
            <div className="company-logo">
              <div className="logo-placeholder">PP</div>
            </div>
            <div className="timeline-content">
              <h3 className="timeline-title">Pixolo Productions</h3>
              <h4 className="timeline-subtitle">Web Developer</h4>
              <div className="timeline-achievements">
                <div className="achievement-item">
                  Designed and developed responsive web and mobile applications using modern front-end technologies.
                </div>
                <div className="achievement-item">
                  Created intuitive user interfaces based on client feedback and market trends.
                </div>
                <div className="achievement-item">
                  Ensured code maintainability by following best practices and iterative improvement processes.
                </div>
              </div>
              <div className="timeline-tags">
                <span className="timeline-tag">JavaScript</span>
                <span className="timeline-tag">Responsive Design</span>
                <span className="timeline-tag">UI/UX</span>
                <span className="timeline-tag">HTML5/CSS3</span>
              </div>
            </div>
          </div>
          
          {/* IM Wealth Builders */}
          <div className="timeline-item">
            <div className="timeline-dot"></div>
            <div className="timeline-date">Nov 2013 - Dec 2017</div>
            <div className="company-logo">
              <div className="logo-placeholder">IW</div>
            </div>
            <div className="timeline-content">
              <h3 className="timeline-title">IM Wealth Builders</h3>
              <h4 className="timeline-subtitle">WordPress Developer & Support (Remote)</h4>
              <div className="timeline-achievements">
                <div className="achievement-item">
                  Provided technical support for WordPress themes and plugins, resolving customer queries and troubleshooting issues.
                </div>
                <div className="achievement-item">
                  Assisted with billing and customer feedback for product improvement.
                </div>
                <div className="achievement-item">
                  Developed custom WordPress solutions to meet specific client requirements.
                </div>
              </div>
              <div className="timeline-tags">
                <span className="timeline-tag">WordPress</span>
                <span className="timeline-tag">PHP</span>
                <span className="timeline-tag">Customer Support</span>
                <span className="timeline-tag">Remote Work</span>
              </div>
            </div>
          </div>
          
          {/* Directi Internet Solutions */}
          <div className="timeline-item">
            <div className="timeline-dot"></div>
            <div className="timeline-date">Jun 2011 - Mar 2013</div>
            <div className="company-logo">
              <div className="logo-placeholder">DI</div>
            </div>
            <div className="timeline-content">
              <h3 className="timeline-title">Directi Internet Solutions</h3>
              <h4 className="timeline-subtitle">Senior Web Solutions Specialist</h4>
              <div className="timeline-achievements">
                <div className="achievement-item">
                  Delivered technical support for hosting services, domains, and SSL certificates.
                </div>
                <div className="achievement-item">
                  Debugged website issues and implemented solutions to improve customer satisfaction.
                </div>
                <div className="achievement-item">
                  Worked with a global team to ensure 24/7 support coverage and maintain service quality.
                </div>
              </div>
              <div className="timeline-tags">
                <span className="timeline-tag">Web Hosting</span>
                <span className="timeline-tag">Technical Support</span>
                <span className="timeline-tag">Domains</span>
                <span className="timeline-tag">SSL</span>
              </div>
            </div>
          </div>
        </div>
        
        <a href="https://www.linkedin.com/in/richardfernand0/" className="timeline-button"  target="_blank" >Linkedin Profile</a>
      </div>
    </section>
  );
};

export default Experience;