import React, { useEffect, useRef } from 'react';
import './about.css';
import profileImage from '../../assets/images/about/profilepicture.jpg'

const About = () => {
  const statsRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            animateStats();
            observer.disconnect();
          }
        });
      },
      { threshold: 0.1 }
    );

    if (statsRef.current) {
      observer.observe(statsRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const animateStats = () => {
    const statElements = document.querySelectorAll('.stat-number');
    
    statElements.forEach(stat => {
      const valueText = stat.textContent;
      const hasPlus = valueText.includes('+');
      const value = parseInt(valueText.replace('+', ''));
      
      let currentValue = 0;
      const increment = value / 30; // Divide by steps for smooth animation
      const duration = 1500; // Total duration in ms
      const stepTime = duration / 30; // Time per step
      
      stat.textContent = '0' + (hasPlus ? '+' : '');
      
      const interval = setInterval(() => {
        currentValue += increment;
        if (currentValue >= value) {
          currentValue = value;
          clearInterval(interval);
        }
        
        stat.textContent = Math.floor(currentValue) + (hasPlus ? '+' : '');
      }, stepTime);
    });
  };

  return (
    <section id="about">
      <div className="container">
        <div className="center-wrapper">
          <h2 className="section-title">About Me</h2>
          <p className="section-subtitle">
            Get to know more about me, my background, and what drives my passion for creating exceptional digital experiences.
          </p>
        </div>
        
        <div className="about-container">
          {/* Decorative elements */}
          <div className="floating-element circle" style={{width: '250px', height: '250px', top: '100px', left: '-80px', backgroundColor: 'var(--secondary)'}}></div>
          <div className="floating-element square" style={{width: '150px', height: '150px', bottom: '50px', right: '-50px', backgroundColor: 'var(--tertiary)'}}></div>
          
          <div className="about-image-container">
            <div className="about-image" style={{ backgroundImage: `url(${profileImage})` }}></div>
            <div className="image-overlay"></div>
          </div>
          
          <div className="about-content">
            <h3 className="about-header">Passionate Front-End Developer with an Eye for Detail</h3>
            <p className="about-text">
              I'm a <span className="highlight">Senior Front-End Developer</span> with over 6 years of experience crafting high-performance, user-centric web applications. My journey in web development began with a simple curiosity about how websites work, which quickly evolved into a passion for creating elegant, functional digital experiences.
            </p>
            <p className="about-text">
              Specializing in the <span className="highlight">React.js ecosystem</span> and modern web technologies, I've helped businesses of all sizes transform their digital presence through responsive, accessible, and scalable solutions. I believe that great code should not only work flawlessly but also be maintainable and adaptable to future needs.
            </p>
            <p className="about-text">
              Recently, I've been expanding my skills into <span className="highlight">AI integration</span>, seeking innovative ways to enhance user experiences and streamline development processes. I'm passionate about continuous learning and staying at the forefront of emerging technologies.
            </p>
            
            <div className="personal-info">
              <div className="info-item">
                <div className="info-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width="18" height="18">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                  </svg>
                </div>
                <div>
                  <div className="info-label">Phone</div>
                  <div className="info-text">+91-9967552082</div>
                </div>
              </div>
              
              <div className="info-item">
                <div className="info-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width="18" height="18">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                  </svg>
                </div>
                <div>
                  <div className="info-label">Name</div>
                  <div className="info-text">Richard Fernando</div>
                </div>
              </div>
              
              <div className="info-item">
                <div className="info-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width="18" height="18">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                </div>
                <div>
                  <div className="info-label">Education</div>
                  <div className="info-text">Bachelors in Information Technology</div>
                </div>
              </div>
              
              <div className="info-item">
                <div className="info-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width="18" height="18">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                </div>
                <div>
                  <div className="info-label">Email</div>
                  <div className="info-text">mail@richardfernando.com</div>
                </div>
              </div>
            </div>
            
            <div className="skills-tags">
              <div className="skill-tag">React</div>
              <div className="skill-tag">JavaScript (ES6+)</div>
              <div className="skill-tag">Tailwind CSS</div>
              <div className="skill-tag">HTML5 & CSS3</div>
              <div className="skill-tag">Python</div>
              <div className="skill-tag">WordPress</div>
              <div className="skill-tag">Git & GitHub</div>
            </div>
            
            <div className="about-stats" ref={statsRef}>
              <div className="stat-item">
                <div className="stat-number">6+</div>
                <div className="stat-label">Years of Experience</div>
              </div>
            </div>
            
            <a href="#contact" className="cta-button">Let's Work Together</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;