import React, { useState } from 'react';
import './projects.css';
import drunkenTalesImg from '../../assets/images/projects/thedrunkentales.png';
import blindchesstrainerImg from '../../assets/images/projects/blindchesstrainer.png';
import cosmicdustImg from '../../assets/images/projects/cosmicdust.jpg';
import newvitalitychriopraktikImg from '../../assets/images/projects/newvitalitychriopraktik.png';
import rsaImg from '../../assets/images/projects/rsa.png';


const Projects = () => {
  const [filter, setFilter] = useState('all');
  
  // Project data
  const projects = [
    {
      id: 1,
      title: 'The Drunken Tales',
      description: "I designed and developed the website for The Drunken Tales, an exclusive luxury music festival, using HTML, CSS, JavaScript, and Jekyll, with a Figma-based design created entirely by me. The site captures the festival's vibrant energy, stunning locations, and high-end experiences, blending sleek aesthetics with seamless functionality. From interactive event details to visually immersive layouts, every element was crafted to enhance user engagement and showcase the festival's unique essence.",
      category: ['javascript', 'Jekyll', 'uiux'],
      image: drunkenTalesImg,
      tags: ['javascript', 'Jekyll', 'Material UI', 'UI/UX'],
      link: 'https://richardfernando.com/thedrunkentales/',
    },
    {
      id: 2,
      title: 'New Vitality Chiropraktik',
      description: "I developed the website for New Vitality Chiropraktik using WordPress, customizing a template to align with the client's vision while integrating various plugins to enhance functionality. The site features multilingual support, ensuring accessibility for a diverse audience. By refining the design and optimizing performance, I created a professional and user-friendly platform that effectively showcases the clinic's services, values, and expertise in chiropractic care.",
      category: ['wordpress', 'uiux'],
      image: newvitalitychriopraktikImg,
      tags: ['WordPress', 'UI/UX', 'Plugin Integrations', 'Google Maps API'],
      link: 'https://newvitalityleipzig.de/',
    },
    {
      id: 3,
      title: 'Cosmic Dust',
      description: "I built Cosmic Dust, an eCommerce store, using WordPress, tailoring the site to reflect the founder's vision. From customizing the design to optimizing functionality, I ensured a seamless shopping experience while maintaining a visually engaging and brand-aligned aesthetic. I also provide ongoing support, ensuring smooth performance, updates, and enhancements as the store evolves.",
      category: ['wordpress', 'E-Commerce', 'Technical Support', 'uiux'],
      image: cosmicdustImg,
      tags: ['wordpress', 'ecommerce', 'css', 'ui'],
      link: 'https://cosmicdust.in/',
    },
    {
      id: 4,
      title: 'Ronaldinho Soccer Academy',
      description: "I developed the Ronaldinho Soccer Academy website, integrating HTML5, CSS3, JavaScript, jQuery, and Bootstrap to create a dynamic, responsive, and visually engaging platform. The site features smooth animations (WOW.js, Animate.css), interactive sliders and carousels (Owl Carousel, BxSlider), and an optimized image gallery (Magnific Popup, Isotope) to enhance user experience.",
      category: ['HTML5', 'CSS', 'jQuery', 'Bootstrap', 'javascript'],
      image: rsaImg,
      tags: ['HTML5', 'CSS', 'jQuery', 'Bootstrap'],
      link: 'https://richardfernando.com/rsa/',
    },
    {
      id: 5,
      title: 'Blind Chess Trainer',
      description: "I developed Blind Chess Trainer, an interactive web-based application designed to enhance chess players visualization and memory skills. To streamline development, I leveraged Claude AI for refining logic, and optimizing the code structure. The app, built with React, offers multiple training modes, including square identification and chess position memorization challenges.",
      category: ['uiux', 'claude', 'react', 'ai'],
      image: blindchesstrainerImg,
      tags: ['React', 'Claude', 'UI/UX'],
      link: 'https://richardfernando.com/blindchesstrainer/',
    }
  ];
  
  // Filter projects based on selected category
  const filteredProjects = filter === 'all' 
    ? projects 
    : projects.filter(project => project.category.includes(filter));
  
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  return (
    <section id="projects">
      <div className="container">
        <div className="center-wrapper">
          <h2 className="section-title">My Projects</h2>
          <p className="section-subtitle">
            Explore some of my recent work that showcases my skills and expertise in web development, AI integration, and no-code solutions.
          </p>
        </div>
        
        <div className="projects-filter">
          <button 
            className={`filter-button ${filter === 'all' ? 'active' : ''}`}
            onClick={() => handleFilterChange('all')}
          >
            All Projects
          </button>
          <button 
            className={`filter-button ${filter === 'react' ? 'active' : ''}`}
            onClick={() => handleFilterChange('react')}
          >
            React
          </button>
          <button 
            className={`filter-button ${filter === 'ai' ? 'active' : ''}`}
            onClick={() => handleFilterChange('ai')}
          >
            AI
          </button>
          <button 
            className={`filter-button ${filter === 'wordpress' ? 'active' : ''}`}
            onClick={() => handleFilterChange('wordpress')}
          >
            WordPress
          </button>
          <button 
            className={`filter-button ${filter === 'javascript' ? 'active' : ''}`}
            onClick={() => handleFilterChange('javascript')}
          >
            Javascript
          </button>
          <button 
            className={`filter-button ${filter === 'uiux' ? 'active' : ''}`}
            onClick={() => handleFilterChange('uiux')}
          >
            UI/UX
          </button>
        </div>
        
        <div className="projects-grid">
          {filteredProjects.map(project => (
            <div 
              key={project.id} 
              className="project-card"
              data-category={project.category.join(',')}
            >
              <div className="project-image">
                <img src={project.image} alt={project.title} />
                <div className="project-overlay">
                  <div className="project-links">
                    <a href={project.link} className="project-link" title="View Live" target="_blank" rel="noopener noreferrer">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                        <polyline points="15 3 21 3 21 9"></polyline>
                        <line x1="10" y1="14" x2="21" y2="3"></line>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div className="project-content">
                <h3 className="project-title">{project.title}</h3>
                <p className="project-description">{project.description}</p>
                <div className="project-tags">
                  {project.tags.map((tag, index) => (
                    <span key={index} className="project-tag">{tag}</span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;