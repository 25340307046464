import React, { useEffect } from 'react';
import './skills.css';

const Skills = () => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const skillBars = entry.target.querySelectorAll('.skill-level-fill');
            skillBars.forEach(bar => {
              const width = bar.getAttribute('data-width');
              bar.style.width = width;
            });
          }
        });
      },
      { threshold: 0.1 }
    );

    const skillGroups = document.querySelectorAll('.skills-grid');
    skillGroups.forEach(group => {
      observer.observe(group);
    });

    return () => observer.disconnect();
  }, []);

  const technicalSkills = [
    { name: 'React', level: 90 },
    { name: 'JavaScript (ES6+)', level: 95 },
    { name: 'HTML5 & CSS3', level: 90 },
    { name: 'Tailwind CSS', level: 85 },
    { name: 'WordPress', level: 88 },
    { name: 'Python', level: 75 },
    { name: 'Git & GitHub', level: 85 }
  ];

  const designSkills = [
    { name: 'Figma', level: 80 },
    { name: 'Adobe Creative Suite', level: 75 },
    { name: 'UI/UX Design', level: 78 }
  ];

  const aiTools = [
    { name: 'Cursor AI', level: 85 },
    { name: 'Replicit', level: 80 },
    { name: 'Lovable', level: 75 },
    { name: 'Claude Code', level: 90 },
    { name: 'Botpress', level: 82 },
    { name: 'N8N', level: 78 }
  ];

  return (
    <section id="skills">
      <div className="container">
        <div className="center-wrapper">
          <h2 className="section-title">My Skills</h2>
          <p className="section-subtitle">
            A comprehensive overview of my technical skills and proficiency levels.
          </p>
        </div>

        <div className="skills-container">
          {/* Technical Skills */}
          <div className="skills-category">
            <h3 className="category-title">
              <span className="category-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <polyline points="16 18 22 12 16 6"></polyline>
                  <polyline points="8 6 2 12 8 18"></polyline>
                </svg>
              </span>
              Technical Skills
            </h3>
            <div className="skills-grid">
              {technicalSkills.map((skill, index) => (
                <div key={`technical-${index}`} className="skill-item">
                  <div className="skill-info">
                    <span className="skill-name">{skill.name}</span>
                    <span className="skill-percentage">{skill.level}%</span>
                  </div>
                  <div className="skill-level">
                    <div 
                      className="skill-level-fill" 
                      data-width={`${skill.level}%`}
                      style={{width: 0}}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Design Skills */}
          <div className="skills-category">
            <h3 className="category-title">
              <span className="category-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <polygon points="1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6"></polygon>
                  <line x1="8" y1="2" x2="8" y2="18"></line>
                  <line x1="16" y1="6" x2="16" y2="22"></line>
                </svg>
              </span>
              Design Skills
            </h3>
            <div className="skills-grid">
              {designSkills.map((skill, index) => (
                <div key={`design-${index}`} className="skill-item">
                  <div className="skill-info">
                    <span className="skill-name">{skill.name}</span>
                    <span className="skill-percentage">{skill.level}%</span>
                  </div>
                  <div className="skill-level">
                    <div 
                      className="skill-level-fill" 
                      data-width={`${skill.level}%`}
                      style={{width: 0}}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* AI Tools */}
          <div className="skills-category">
            <h3 className="category-title">
              <span className="category-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M12 2a2 2 0 0 1 2 2c0 .74-.4 1.39-1 1.73V7h1a7 7 0 0 1 7 7h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v1a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-1H2a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h1a7 7 0 0 1 7-7h1V5.73c-.6-.34-1-.99-1-1.73a2 2 0 0 1 2-2z"></path>
                  <circle cx="7.5" cy="14.5" r="1.5"></circle>
                  <circle cx="16.5" cy="14.5" r="1.5"></circle>
                </svg>
              </span>
              AI Tools
            </h3>
            <div className="skills-grid">
              {aiTools.map((skill, index) => (
                <div key={`ai-${index}`} className="skill-item">
                  <div className="skill-info">
                    <span className="skill-name">{skill.name}</span>
                    <span className="skill-percentage">{skill.level}%</span>
                  </div>
                  <div className="skill-level">
                    <div 
                      className="skill-level-fill" 
                      data-width={`${skill.level}%`}
                      style={{width: 0}}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;