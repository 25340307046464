import React, { useEffect, useRef } from 'react';
import './hero.css';

const Hero = () => {
  const typingTextRef = useRef(null);

  useEffect(() => {
    if (!typingTextRef.current) return;

    const texts = [
      "Senior Front-End Developer",
      "React Specialist",
      "WordPress",
      "UI/UX",
      "AI Integrations"
    ];

    let textIndex = 0;
    let charIndex = 0;
    let isDeleting = false;

    const typeText = () => {
      const currentText = texts[textIndex];
      
      if (isDeleting) {
        typingTextRef.current.textContent = currentText.substring(0, charIndex - 1);
        charIndex--;
      } else {
        typingTextRef.current.textContent = currentText.substring(0, charIndex + 1);
        charIndex++;
      }
      
      if (!isDeleting && charIndex === currentText.length) {
        // Pause at the end of typing
        isDeleting = true;
        setTimeout(typeText, 1500);
      } else if (isDeleting && charIndex === 0) {
        isDeleting = false;
        textIndex = (textIndex + 1) % texts.length;
        // Pause before typing next word
        setTimeout(typeText, 500);
      } else {
        // Speed of typing and deleting
        const typingSpeed = isDeleting ? 50 : 100;
        setTimeout(typeText, typingSpeed);
      }
    };
    
    // Start the typing animation after a delay
    const typingTimeout = setTimeout(typeText, 1000);
    
    return () => clearTimeout(typingTimeout);
  }, []);

  return (
    <section id="home" className="hero">
      <div className="shapes">
        <div className="shape shape-1"></div>
        <div className="shape shape-2"></div>
      </div>
      
      <div className="container">
        <div className="hero-content">
          <p className="subtitle">Hello, I'm</p>
          <h1 className="title">Richard Fernando</h1>
          <div className="typing-container">
            <span className="typing-text" ref={typingTextRef}>Senior Front-End Developer</span>
          </div>
          <p className="description">
            I craft high-performance, user-centric web applications using modern technologies. 
            Specialized in the React ecosystem with a growing focus on AI integration and no-code solutions.
          </p>
          <div className="cta-wrapper">
            <a href="#projects" className="cta-primary">View My Work</a>
            <a href="#contact" className="cta-secondary">Let's Connect</a>
          </div>
        </div>
      </div>
      
      <div className="scroll-indicator">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M12 5v14m-7-7l7 7 7-7"/>
        </svg>
      </div>
      
      <div className="tech-stack">
        <div className="tech-item">React</div>
        <div className="tech-item">JavaScript</div>
        <div className="tech-item">Tailwind CSS</div>
        <div className="tech-item">Python</div>
        <div className="tech-item">AI Integration</div>
      </div>
    </section>
  );
};

export default Hero;